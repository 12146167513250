import useStore from "store";

export const debugMode = () => {
	return useStore.getState().debug;
}

export const getConfig = () => {
	return useStore.getState().config;
}
 
export function setConfig(data) {
	useStore.getState().dispatch({
		type: 'setConfig',
		data: data
	});
}

export function getAjaxUrl() {
	let url = 'http://plenoilapp.local/';

	if ( !debugMode() ) {
		if ( window.location.hostname.indexOf('desarrollo') !== -1 ) url = 'https://desarrolloplenoil.com/';
		else url = 'https://plenoilapp.com/';
	}

	return url;
}