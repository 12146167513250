import axios from 'axios';

export default class User {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getData = () => {
		this.newAxiosCancelToken();

		return axios.get('api/users/getData', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static updateData = (data) => {
		this.newAxiosCancelToken();

		return axios.post('api/users/updateData', data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static updateInvoicingData = (data) => {
		this.newAxiosCancelToken();

		return axios.post('api/users/updateInvoicingData', data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static stationsList = () => {
		this.newAxiosCancelToken();

		return axios.get('api/users/stationsList', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static setFavoriteStation = (station_id) => {
		this.newAxiosCancelToken();

		return axios.post('api/users/setFavoriteStation', {
			station_id: station_id,
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static getProvinces = () => {
		this.newAxiosCancelToken();

		return axios.get('api/users/provinces', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}