import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { formatNumber, jsonToArray, ucfirst } from 'helpers/generic';
import BarsReport from './BarsReport';
import PieReport from './PieReport';
import { default as ReportService } from 'services/Report';
import { default as RepostajeService } from 'services/Repostaje';

const ContainerStyled = styled.div` 	
	margin-top: 30px 0;
`;

const BarsWrapper = styled.div` 
	
`;

const MensualReport = styled.div` 
	height: 100%;

	h2 {
		color: var(--plenoil-orange2);
		text-align: center;
		font-size: 35px;
		font-weight: 700;
		margin-top: 20px;
		letter-spacing: 3px;
	}

	h3 {
		text-align: center;
		font-size: 16px;
		margin-bottom: 30px;
	}

	h4 {
		margin-top: 0;
		font-size: 13px;
		text-transform: uppercase;
	}

	table {
		width: 100%;

		tr {
			td {
				font-weight: 500;

				&:last-of-type {
					text-align: right;
				}
			}
		}
	}
`;

const PieReportWrapper = styled.div` 
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
`;

export default function Consumptions() {
	let [plates, setPlates] = useState([]);
	let [year, setYear] = useState(moment().format('YYYY'));
	let [month, setMonth] = useState(moment().format('MM'));
	let [plate, setPlate] = useState(null);
	let [statistics, setStatistics] = useState([]);
	let [reportMonthSummary, setReportMonthSummary] = useState(null);
	let [repostajeMonthSummary, setRepostajeMonthSummary] = useState(null);
	let [salesTotalByMatricula, setSalesTotalByMatricula] = useState(null);

	useEffect(() => {
		let getPlates = async () => {
			let plates = await ReportService.listMatriculas();
			setPlates(plates);
		}
		getPlates();
	}, []);

	useEffect(() => {
		let getStatistics = async () => {
			let statistics = await ReportService.getSalesTotalByMonths(year, plate);
			setStatistics(statistics);
		}
		getStatistics();
	}, [year, plate]);

	useEffect(() => {
		let getMonthSummary = async () => {
			let date = year + '-' + ((""+month).length < 2 ? '0'+month : month + '-01');
			
			let reportMonthSummary = await ReportService.monthSummary(date);
			setReportMonthSummary(reportMonthSummary);

			let repostajeMonthSummary = await RepostajeService.monthSummary(date);
			setRepostajeMonthSummary(repostajeMonthSummary);

			let salesTotalByMatricula = await ReportService.getSalesTotalByMatricula(year, month);
			setSalesTotalByMatricula(salesTotalByMatricula);
		}
		getMonthSummary();
	}, [year, month]);

	return (
		<LoggedLayout>
			<ContainerStyled>
				<div className="row">
					<div className="col-md-12 mb-5">
						<NavLink to="/" id="btn-back"><i className="bi bi-chevron-left"></i> CONSUMOS</NavLink>
					</div>

					<div className="col-md-12 mb-4">
						<BarsWrapper className="box-plenoil box-plenoil-padded">
							<div className="row">
								<div className="col-md-12">
									<h1 className="box-title">Resumen anual de consumo:</h1>
									<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
										{(() => {
											let years = [];
											for(let i = 2018; i<=moment().format('YYYY'); i++) {
												years.push(<option key={i} value={i}>{i}</option>);
											}
											return years.reverse();
										})()}
									</select>

									<select className="box-select" value={plate ?? ''} onChange={(e) => setPlate(e.target.value)}>
										<option value="">TODAS</option>
										{plates && jsonToArray(plates).map((el, idx) => {
											return (
												<option key={idx} value={el.value}>{el.value}</option>
											)
										})}
									</select>
								</div>
								<div className="col-md-12">
									<BarsReport statistics={statistics} />
								</div>
							</div>
						</BarsWrapper>
					</div>
				</div>
				
				<div className="row row-eq-height mb-5">
					<div className="col-md-7 mb-4 mb-md-0">
						<MensualReport className="box-plenoil box-plenoil-padded">
							<h1 className="box-title">Resumen mensual de consumo:</h1>
							<select className="box-select" value={month ?? ''} onChange={(e) => setMonth(e.target.value)}>
								{(() => {
									let months = [];
									for(let i = 1; i<=12; i++) {
										let tmpMonth = moment('2022-' + (i < 10 ? '0'+i : i ) + '-01').format('MMMM');
										months.push(<option key={i} value={i}>{ucfirst(tmpMonth)}</option>);
									}
									return months;
								})()}
							</select>
							<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
								{(() => {
									let years = [];
									for(let i = 2018; i<=moment().format('YYYY'); i++) {
										years.push(<option key={i} value={i}>{i}</option>);
									}
									return years.reverse();
								})()}
							</select>

							<div className="mt-3">
								<h2>{formatNumber(repostajeMonthSummary?.totalEuros ?? 0, 2, true, true)} €</h2>
								<h3>Consumo total mensual: {formatNumber(repostajeMonthSummary?.totalLiters ?? 0, 2, true, true)} L</h3>

								<hr className="mb-2" />
								<h4>Datos de interés</h4>
								<table>
									<tbody>
										<tr>
											<td>Último repostaje:</td>
											<td>{reportMonthSummary?.last_repostaje ? reportMonthSummary.last_repostaje : '-'}</td>
										</tr>
										<tr>
											<td>Días entre repostajes:</td>
											<td>{reportMonthSummary?.daysBetweenRepostajes !== undefined ? reportMonthSummary.daysBetweenRepostajes : '-'}</td>
										</tr>
									</tbody>	
								</table>

								<hr className="mt-5 mb-2" />
								<h4>Combustibles</h4>
								<table>
									<tbody>
										<tr>
											<td>Total diesel:</td>
											<td>{reportMonthSummary?.litersOilDiesel ? formatNumber(reportMonthSummary.litersOilDiesel, 2, true, true) + ' L' : '-'}</td>
										</tr>
										<tr>
											<td>Total SP 95:</td>
											<td>{reportMonthSummary?.litersOil95 ? formatNumber(reportMonthSummary.litersOil95, 2, true, true) + ' L' : '-'}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</MensualReport>
					</div>

					<div className="col-md-5">
						<PieReportWrapper className="box-plenoil box-plenoil-padded">
							<div className="align-self-start">
								<h1 className="box-title">Consumo por vehículos:</h1>
								<select className="box-select" value={month ?? ''} onChange={(e) => setMonth(e.target.value)}>
									{(() => {
										let months = [];
										for(let i = 1; i<=12; i++) {
											let tmpMonth = moment('2022-' + (i < 10 ? '0'+i : i ) + '-01').format('MMMM');
											months.push(<option key={i} value={i}>{ucfirst(tmpMonth)}</option>);
										}
										return months;
									})()}
								</select>
								<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
									{(() => {
										let years = [];
										for(let i = 2018; i<=moment().format('YYYY'); i++) {
											years.push(<option key={i} value={i}>{i}</option>);
										}
										return years.reverse();
									})()}
								</select>	
							</div>
							<PieReport statistics={salesTotalByMatricula} />
						</PieReportWrapper>	
					</div>
				</div>
			</ContainerStyled>
		</LoggedLayout>
	);
}


