import React, { useState } from "react";
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { debugMode } from 'helpers/config'; 
import { assets } from "helpers/generic";
import { getUser } from "helpers/user";

const Background = styled.div` 
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url('/assets/img/fondo-area-clientes.jpg');
	background-size: cover;
	z-index: -1;
	opacity: 0.2;
	filter: grayscale(100%);
`;

const Navbar = styled.div`
	box-shadow: var(--plenoil-shadow);
	background: var(--plenoil-white);

	#logo {
		width: 180px;
		cursor: pointer;
	}

	#btn-navbar-toggler {
		display: none;

		@media (max-width: 768px) {
			display: inline-block;
		}
	}

	#user-meta {
		display: none !important;
		margin-left: auto;
		justify-content: end;
		align-items: center;

		@media (max-width: 768px) {
			width: 100%;
			justify-content: center;
			margin-top: 20px;
		}

		&.show {
			display: flex !important;
		}

		#user-name {
			font-weight: 500;
		}

		#btn-logout {
			display: inline-flex;
			align-items: center;
			font-size: 15px;
			padding: 3px 10px;
			text-decoration: none;

			i {
				margin-left: 10px;
				font-size: 25px;
				line-height: 15px;
			}
		}
	}

	#menu {
		display: none !important;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 15px;

		&.show {
			display: flex !important;
		}

		a {
			color: var(--plenoil-black);
			text-decoration: none;
			display: block;
			width: 100%;
			border-top: 1px solid var(--plenoil-gray2);
			padding-top: 12px;
			font-weight: 500;

			&:not(:last-of-type) {
				margin-right: 40px;
			}

			&.active {
				border-color: var(--plenoil-black);
			}
		}

		@media (max-width: 768px) {
			flex-direction: column;
			text-align: center;

			a {
				margin-right: 0 !important;
			}
		}
	}
`;

const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	height: 100%;
	display: flex;
	flex-direction: column;

	@media (max-width: 1285px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	height: 100%;
	margin-top: 40px;

	
`;

const LoggedLayoutStyled = styled.div`

`


export default function AppLayout(props) {
	const [sidebarVisible, setSidebarVisible] = useState(true);
    
	return (
		<LoggedLayoutStyled>
			{ props.showBackground && <Background /> }
			<Navbar>
				<Container>
					<div className="navbar navbar-expand-lg navbar-light">
						<div className="navbar-brand">
							<img id="logo" src={assets('assets/img/Plenoil-logo-2022.svg')} alt="Plenoil" onClick={() => window.open('https://plenoil.es')} />
							{ debugMode() && 
								<span className="badge bg-danger text-white ms-2">debug</span>
							}
						</div>
						<button className="btn" id="btn-navbar-toggler" tabIndex="-1" onClick={() => setSidebarVisible(!sidebarVisible)}>
							<span className="navbar-toggler-icon"></span>
						</button>
						<div id="user-meta" className={(sidebarVisible ? 'show' : '')}>
							Hola&nbsp;<span id="user-name">{ getUser()?.name }</span>
							<NavLink to="/logout" className="btn-plenoil btn-plenoil-blue2 ms-4" id="btn-logout">Cerrar sesión <i className="bi bi-x"></i></NavLink>
						</div>
					</div>
					<div id="menu" className={(sidebarVisible ? 'show' : '')}>
						<NavLink to="/">Inicio</NavLink>
						<NavLink to="/consumptions">Consumos</NavLink>
						<NavLink to="/refuelings">Repostajes</NavLink>
						<NavLink to="/invoices">Facturas</NavLink>
						<NavLink to="/account">Mis datos</NavLink>
					</div>
				</Container>
			</Navbar>
			<Content>
				<Container>
					{props.children}
				</Container>	
			</Content>	
		</LoggedLayoutStyled>
	);
}