import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { getAjaxUrl } from 'helpers/config';
import useStore from "store";

import Login from "pages/Login";
import Logout from "pages/Logout";
import Register from "pages/Register";

import Dashboard from "pages/Dashboard";
import Consumptions from "pages/Consumptions";
import Refuelings from "pages/Refuelings";
import Invoices from "pages/Invoices";
import Account from "pages/Account";

const MainContainer = styled.div`
	height: 100%;
`;

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);
	
	// Axios global
    axios.defaults.baseURL = getAjaxUrl();
	axios.defaults.headers.common['Authorization'] = getUser()?.accessToken ? 'Bearer ' + getUser()?.accessToken : null;
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error
        if ( error.response && error.response.status === 401 ) {
			if (getUser()?.id) {
				setUser(null, true);
				window.location.reload();
			}
        }

        // Default
        return Promise.reject(error);
    });

	return (
		<MainContainer forceReload={forceReload}>
			{ (!getUser() && location.pathname !== '/login' && location.pathname !== '/register') &&
				<Navigate to="/login" />
			}

			<Routes>
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />
				<Route path="register" element={<Register />} />

				{getUser()?.id &&
					<React.Fragment>
						<Route path="/" element={<Dashboard />} />
						<Route path="/consumptions" element={<Consumptions />} />
						<Route path="/refuelings" element={<Refuelings />} />
						<Route path="/invoices" element={<Invoices />} />
						<Route path="/account" element={<Account />} />
					</React.Fragment>
				}
			</Routes>

			<ToastContainer
				position="bottom-right"
				autoClose={2000}
			/>
		</MainContainer>
	);
}

export default App;