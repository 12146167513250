import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/es';
import LoggedLayout from 'layouts/LoggedLayout';
import { ucfirst, assets, jsonToArray, formatNumber } from 'helpers/generic';
import { default as RepostajeService } from 'services/Repostaje';
import TicketPreview from './TicketPreview';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;

	table {
		th, td {
			&:nth-child(1) {
				width: 20px;
			}
			&:nth-child(2) {
				width: 100px;
				text-align: center;
				white-space: nowrap;
			}
			&:nth-child(4) {
				width: 100px;
				text-align: center;
				white-space: nowrap;
			}
			&:nth-child(5) {
				width: 80px;
				text-align: center;
			}
			&:nth-child(6) {
				width: 80px;
				text-align: center;
			}
			&:nth-child(7) {
				width: 80px;
				text-align: center;
			}
			&:nth-child(8) {
				width: 100px;
				text-align: center;

				button {
					img {
						width: 20px;
					}
				}
			}
		}
	}

	#empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--plenoil-gray2);
		font-weight: 500;
		font-size: 16px;
		margin: 80px 10px;

		img {
			display: block;
			width: 120px;
			margin-bottom: 10px;
		}
	}
`;

export default function Refuelings() {
	let [monthSales, setMonthSales] = useState(undefined);
	let [year, setYear] = useState(moment().format('YYYY'));
	let [month, setMonth] = useState(moment().format('MM'));
	let [previewId, setPreviewId] = useState(null);

	useEffect(() => {
		const getData = async () => {
			let monthSales = await RepostajeService.listMonthSales(year, month);
			setMonthSales(monthSales);
		}
		getData();
	}, [year, month]);

	const previewTicket = (id) => {
		setPreviewId(id);
	}

	return (
		<LoggedLayout>
			<ContainerStyled>
				<div className="row">
					<div className="col-md-12 mb-5">
						<NavLink to="/" id="btn-back"><i className="bi bi-chevron-left"></i> REPOSTAJES</NavLink>
					</div>

					<div className="col-md-8 mb-4">
						<div className="box-plenoil box-plenoil-padded">
							<div className="mb-4">
								<h1 className="box-title">Resumen mensual de consumo:</h1>
								<select className="box-select" value={month ?? ''} onChange={(e) => setMonth(e.target.value)}>
									{(() => {
										let months = [];
										for(let i = 1; i<=12; i++) {
											let tmpMonth = moment('2022-' + (i < 10 ? '0'+i : i ) + '-01').format('MMMM');
											months.push(<option key={i} value={i}>{ucfirst(tmpMonth)}</option>);
										}
										return months;
									})()}
								</select>
								<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
									{(() => {
										let years = [];
										for(let i = 2018; i<=moment().format('YYYY'); i++) {
											years.push(<option key={i} value={i}>{i}</option>);
										}
										return years.reverse();
									})()}
								</select>
							</div>
							
							<table className="box-table">
								<thead>
									<tr>
										<th></th>
										<th>FECHA</th>
										<th>ESTACIÓN</th>
										<th>COMBUSTIBLE</th>
										<th>LITROS</th>
										<th>PRECIO</th>
										<th>IMPORTE</th>
										<th>VISTA PREVIA</th>
									</tr>
								</thead>
								<tbody>
									{monthSales && jsonToArray(monthSales).map((el, idx) => {
										let ticket = el.value;

										return (
											<tr key={idx}>
												<td></td>
												<td>{ticket.date.replaceAll('/', '-')}</td>
												<td>{ticket.station?.name}</td>
												<td>{ticket.product}</td>
												<td>{formatNumber(ticket.liters, 2, true, true)}</td>
												<td>{formatNumber(ticket.price, 3, true, true)}€</td>
												<td>{formatNumber(ticket.amount, 2, true, true)}€</td>
												<td>
													<button className="btn btn-link btn-sm" onClick={() => previewTicket(el.key)}>
														<img src={assets('assets/img/icono-vista-previa.svg')} alt="vista previa" />
													</button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							{ (!monthSales || !Object.keys(monthSales).length) &&
								<div id="empty">
									<img src={assets('assets/img/icono-no-repostajes.svg')} alt="no hay repostajes" />
									No hay repostajes
								</div>
							}
						</div>
					</div>
					<div className="col-md-4 mb-4">
						<TicketPreview id={previewId} />
					</div>
				</div>
			</ContainerStyled>
		</LoggedLayout>
	);
}


