import axios from 'axios';

export default class Repostaje {

	static axiosCancelToken = axios.CancelToken.source();

	static getSale = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/repostaje/getSale', {
			params: {
				id: id
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static downloadTicket = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/repostaje/downloadTicket', {
			params: {
				id: id
			},
			responseType: 'blob',
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static listMonthSales = (year, month) => {
		this.newAxiosCancelToken();

		return axios.get('api/repostaje/listMonthSales', {
			params: {
				year: year,
				month: month
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static monthSummary = (date) => {
		this.newAxiosCancelToken();

		return axios.get('api/repostaje/monthSummary', {
			params: {date: date},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}