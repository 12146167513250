import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import 'moment/locale/es';
import LoggedLayout from 'layouts/LoggedLayout';
import CristalLoader from 'components/CristalLoader';
import { jsonToArray } from 'helpers/generic';
import { default as UsersService } from 'services/User';
import { toast } from 'react-toastify';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;

	h2 {
		font-size: 15px;
	}

	input.input-plenoil,
	select.input-plenoil {
		padding: 10px 20px;
		font-size: 15px;
	}

	.btn-plenoil {
		padding: 10px 50px;
		font-size: 15px;
	}

	#invoices-period {
		
		label {
			display: flex;
			align-items: center;
			font-size: 12px;
			font-weight: 500;

			&:first-of-type {
				margin-right: 20px;
			}
		}

		input[type=radio] {
			width: 25px;
			height: 25px;
			margin-left: 10px;
		}
	}

	#vehicles-wrapper {
		.vehicle-row {
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			button.remove {
				background: var(--plenoil-orange2);
				border-radius: 50%;
				border: 0;
				color: var(--plenoil-white);
				margin-right: 10px;
				width: 16px;
				height: 16px;
				font-size: 11px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		#add-vehicle {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 10px 20px;
		}
	}

	#vehicles-help {
		color: var(--plenoil-blue2);
		width: 80%;
		margin: 0 auto;
		margin-top: 20px;
		font-weight: 500;
	}
`;

const ConcentradorProcessCristal = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.8);
	border-radius: 30px;
	padding: 20px;

	span {
		background: white;
		color: var(--plenoil-blue2);
		border-radius: 30px;
		text-align: center;
		display: block;
		padding: 10px 20px;
		box-shadow: var(--plenoil-shadow);
	}
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		font-size: 20px;
		text-align: center;
		background: white;
	}
`;

let checkConcentradorProcessInterval = null;

export default function Account() {
	let [forceReload, setForceReload] = useState(null);
	let [loading, setLoading] = useState(false);
	let [provinces, setProvinces] = useState([]);
	let [data, setData] = useState({
		matriculas: []
	});
	let [errors, setErrors] = useState({});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);

			let provinces = await UsersService.getProvinces();
			setProvinces(provinces);

			let data = await UsersService.getData();
			let province_exists = provinces.findIndex(el => el.name.replaceAll(' ', '').toLowerCase() === data.province.replaceAll(' ', '').toLowerCase()) !== -1;
			if  (!province_exists) data.province = '';
			setData(data);

			setLoading(false);
		}
		getData();
	}, [forceReload]);

	useEffect(() => {
		if ( data.concentrador_process ) {
			clearInterval(checkConcentradorProcessInterval);
			checkConcentradorProcessInterval = setInterval(() => {
				setForceReload(Math.random());
			}, 5000);
		}

		return function cleanup() {
			clearInterval(checkConcentradorProcessInterval);
		}
	}, [data]);
	
	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const setInvoicesPeriod = (value) => {
		setData((prev) => ({...prev, invoices_period: value}));
	}

	const addVehicle = () => {
		let newVehicles = {...data.matriculas};
		newVehicles[''] = '';
		setData((prev) => ({...prev, matriculas: newVehicles}));
	}

	const updateVehicle = (idx, value) => {
		let newVehicles = {...data.matriculas};
		newVehicles[idx] = value;
		setData((prev) => ({...prev, matriculas: newVehicles}));
	}

	const removeVehicle = (idx) => {
		let newVehicles = {...data.matriculas};
		delete newVehicles[idx];
		setData((prev) => ({...prev, matriculas: newVehicles}));
	}

	const saveData = async () => {
		setErrors({});
		setLoading(true);
		let result = await UsersService.updateData({
			name: data.name,
			email: data.email,
			postalcode: data.postalcode,
			province: data.province,
			city: data.city,
			address: data.address,
			phone: data.phone,
			password: data.password
		});
		if (result.status) {
			toast.success('Datos recibidos');
			setForceReload(Math.random()); // Force reload data to show concentrador cristal
		} else {
			setErrors(result.errors)
			toast.error('Error al recibir los datos');
		}
		setLoading(false);
	}

	const saveInvoicingData = async () => {
		setErrors({});
		setLoading(true);
		let result = await UsersService.updateInvoicingData({
			invoices_period: data.invoices_period === 2 ? 'individual' : 'mensual',
			vehicles: data.matriculas
		});
		if (result.status) {
			toast.success('Datos actualizados');
			setForceReload(Math.random()); // Force reload data to show concentrador cristal
		} else {
			setErrors(result.errors)
			toast.error('Datos no actualizados');
		}
		setLoading(false);
	}

	return (
		<LoggedLayout>
			{loading && <CristalLoader />}
			<ContainerStyled>
				<div className="row">
					<div className="col-md-12 mb-5">
						<NavLink to="/" id="btn-back"><i className="bi bi-chevron-left"></i> MIS DATOS</NavLink>
					</div>

					<div className="col-md-8 mb-4">
						<div className="box-plenoil box-plenoil-padded position-relative">
							{ data.concentrador_process && <ConcentradorProcessCristal><span>Actualización en progreso. En breve estará disponible. Puedes seguir navegando.</span></ConcentradorProcessCristal> }
							<div className="mb-4">
								<h1 className="box-title">Datos de usuario</h1>
							</div>

							<div className="row">
								<div className="col-md-6">
									<div className="mb-2">
										<label className="label-plenoil">NOMBRE Y APELLIDOS / NOMBRE DE LA EMPRESA</label>
										<input type="text" className="input-plenoil" placeholder="Nombre Empresa SL" value={data?.name ?? ''} onChange={(e) => setDataField('name', e.target.value)} />
										{ errors['name'] && <div className="invalid-feedback d-block">{errors['name'][0]}</div> }
									</div>
								</div>
								<div className="col-md-6">
									<div className="mb-2">
										<label className="label-plenoil">TELÉFONO</label>
										<input type="text" className="input-plenoil" placeholder="XXXXXXXXX" value={data?.phone ?? ''} onChange={(e) => setDataField('phone', e.target.value)} />
										{ errors['phone'] && <div className="invalid-feedback d-block">{errors['phone'][0]}</div> }
									</div>
								</div>
								<div className="col-md-6">
									<div className="mb-2">
										<label className="label-plenoil">EMAIL</label>
										<input type="email" className="input-plenoil" placeholder="email@ejemplo.com" value={data?.email ?? ''} onChange={(e) => setDataField('email', e.target.value)} />
										{ errors['email'] && <div className="invalid-feedback d-block">{errors['email'][0]}</div> }
									</div>
								</div>
								<div className="col-md-6">
									<div className="mb-2">
										<label className="label-plenoil">CONTRASEÑA</label>
										<input type="text" className="input-plenoil" placeholder="******" value={data?.password ?? ''} onChange={(e) => setDataField('password', e.target.value)} />
										{ errors['password'] && <div className="invalid-feedback d-block">{errors['password'][0]}</div> }
									</div>
								</div>
								<div className="col-md-12">
									<div className="mb-2">
										<label className="label-plenoil">DIRECCIÓN</label>
										<input type="text" className="input-plenoil" placeholder="C/ Ejemplo, 10, 1A" value={data?.address ?? ''} onChange={(e) => setDataField('address', e.target.value)} />
										{ errors['address'] && <div className="invalid-feedback d-block">{errors['address'][0]}</div> }
									</div>
								</div>
								<div className="col-md-4">
									<div className="mb-2">
										<label className="label-plenoil">PROVINCIA</label>
										<select 
											className="input-plenoil" 
											placeholder="Tu provincia" 
											value={data?.province ?? ''} 
											onChange={(e) => setDataField('province', e.target.value)}
										>
											<option value="">Tu provincia</option>
											{provinces?.map((el, idx) => {
												return (
													<option value={el.name.toUpperCase()} key={idx}>{el.name}</option>
												)
											})}
										</select>
										{ errors['province'] && <div className="invalid-feedback d-block">{errors['province'][0]}</div> }
									</div>
								</div>
								<div className="col-md-4">
									<div className="mb-2">
										<label className="label-plenoil">LOCALIDAD</label>
										<input type="text" className="input-plenoil" placeholder="Tu localidad" value={data?.city ?? ''} onChange={(e) => setDataField('city', e.target.value)} />
										{ errors['city'] && <div className="invalid-feedback d-block">{errors['city'][0]}</div> }
									</div>
								</div>
								<div className="col-md-4">
									<div className="mb-2">
										<label className="label-plenoil">CÓDIGO POSTAL</label>
										<input type="text" className="input-plenoil" placeholder="XXXXXX" value={data?.postalcode ?? ''} onChange={(e) => setDataField('postalcode', e.target.value)} />
										{ errors['postalcode'] && <div className="invalid-feedback d-block">{errors['postalcode'][0]}</div> }
									</div>
								</div>
								<div className="col-md-12 mt-4 text-center">
									<button className="btn-plenoil btn-plenoil-blue2" onClick={saveData}>Guardar</button>
								</div>
							</div>
						</div>					
					</div>
					<div className="col-md-4 mb-4">
						<div className="box-plenoil box-plenoil-padded position-relative mb-4">
							{ data.concentrador_process && <ConcentradorProcessCristal><span>Actualización en progreso. En breve estará disponible. Puedes seguir navegando.</span></ConcentradorProcessCristal> }

							<div className="mb-4">
								<h1 className="box-title">Datos de facturación</h1>
							</div>
							
							<div className="mb-4" id="invoices-period">
								<h2>Generar facturas</h2>
								<div className="d-flex">
									<label className="plenoil-label">
										POR SUMINISTRO
										<input type="radio" checked={data.invoices_period === 2} onChange={() => setInvoicesPeriod(2)} />
									</label>
									<label className="plenoil-label">
										MENSUALES
										<input type="radio" checked={data.invoices_period === 0} onChange={() => setInvoicesPeriod(0)} />
									</label>
								</div>
							</div>

							<div>
								<h2>Vehículos</h2>
								<div id="vehicles-wrapper">
									{data?.matriculas && jsonToArray(data.matriculas).map((el, idx) => {
										return (
											<div key={idx} className="vehicle-row">
												<button onClick={() => removeVehicle(el.key)} className="remove"><i className="bi bi-x"></i></button>
												<input 
													type="text" 
													className="input-plenoil" 
													value={el.value} 
													onChange={(e) => updateVehicle(el.key, e.target.value)} 
													placeholder="Introduzca matrícula" 
												/>
											</div>
										);
									})}
									
									<div className="vehicle-row">
										<button className="remove invisible"></button>
										<button className="btn-plenoil btn-plenoil-blue2 outline" id="add-vehicle" onClick={addVehicle}>
											Añadir vehículo <i className="bi bi-plus"></i>
										</button>
									</div>
								</div>

								<div className="mt-4 text-center">
									<button className="btn-plenoil btn-plenoil-blue2" onClick={saveInvoicingData}>Guardar</button>
								</div>
							</div>
						</div>	

						<div id="vehicles-help">
							<div className="mb-2">
								<b>Por suministro:</b> recibirá una factura por cada repostaje.
							</div>
							<div>
								<b>Mensual:</b> recibirá una factura mensual con todos los repostajes.
							</div>
						</div>	
					</div>
				</div>
			</ContainerStyled>
		</LoggedLayout>
	);
}


