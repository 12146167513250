import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/es';
import LoggedLayout from 'layouts/LoggedLayout';
import { ucfirst, assets, jsonToArray, formatNumber, downloadBlob } from 'helpers/generic';
import { default as InvoicesService } from 'services/Invoices';
import CristalLoader from 'components/CristalLoader';

const ContainerStyled = styled.div` 
	margin-top: 30px 0;

	table {
		th, td {
			&:nth-child(1) {
				width: 20px;

				input {
					cursor: pointer;
				}
			}
			&:nth-child(2) {
				width: 100px;
				text-align: center;
				white-space: nowrap;
			}
			&:nth-child(4) {
				width: 100px;
				text-align: center;
			}
			&:nth-child(5) {
				width: 100px;
				text-align: center;

				button {
					img {
						width: 20px;
					}
				}
			}
		}
	}

	#buttons {
		display: flex;
		justify-content: center;
		margin-top: 30px;

		.btn-plenoil {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;

			&:not(:last-of-type) {
				margin-right: 15px;
			}

			i {
				margin-left: 10px;
				font-size: 18px;
				line-height: 0px;
			}
		}
	}

	#empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--plenoil-gray2);
		font-weight: 500;
		font-size: 16px;
		margin: 80px 10px;

		img {
			display: block;
			width: 120px;
			margin-bottom: 10px;
		}
	}

	#ticket-to-invoices-link {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: var(--plenoil-black);
		text-decoration: none;
		font-weight: 500;
		padding: 80px 50px;
		font-size: 20px;
		line-height: 20px;
		text-align: center;

		img {
			width: 120px;
			margin-bottom: 20px;
		}
	}
`;

export default function Invoices() {
	let [loading, setLoading] = useState(false);
	let [monthInvoices, setMonthInvoices] = useState(undefined);
	let [year, setYear] = useState(moment().format('YYYY'));
	let [month, setMonth] = useState(moment().format('MM'));
	let [selectedIds, setSelectedIds] = useState([]);

	useEffect(() => {
		const getData = async () => {
			let monthInvoices = await InvoicesService.listMonthInvoices(year, month);
			setMonthInvoices(monthInvoices);
		}
		getData();

		setSelectedIds([]);
	}, [year, month]);

	const toggleSelected = (id, status) => {
		let newSelectedIds = [...selectedIds];
		if (status) {
			newSelectedIds.push(id);
		} else {
			let idx = newSelectedIds.indexOf(id);
			if (idx !== -1) newSelectedIds.splice(idx, 1);
		}
		setSelectedIds(newSelectedIds);
	}

	const selectAll = () => {
		let newSeletedIds = jsonToArray(monthInvoices ?? []).map((el) => el.key);
		setSelectedIds(newSeletedIds);
	}
	
	const unselect = () => {
		setSelectedIds([]);
	}

	const downloadInvoice = async (id) => {
		setLoading(true);
		let result = await InvoicesService.downloadInvoices([id]);
		if ( result ) downloadBlob(result, 'plenoil_factura_' + id + '.pdf');
		setLoading(false);
	}

	const downloadInvoices = async () => {
		setLoading(true);
		let result = await InvoicesService.downloadInvoices(selectedIds);
		if ( result ) downloadBlob(result, 'plenoil_facturas_' + month + '-' + year + '.pdf');
		setLoading(false);
	}

	return (
		<LoggedLayout>
			{loading && <CristalLoader />}
			<ContainerStyled>
				<div className="row">
					<div className="col-md-12 mb-5">
						<NavLink to="/" id="btn-back"><i className="bi bi-chevron-left"></i> FACTURAS</NavLink>
					</div>

					<div className="col-md-8 mb-4">
						<div className="box-plenoil box-plenoil-padded">
							<div className="mb-4">
								<h1 className="box-title">Resumen de facturas de:</h1>
								<select className="box-select" value={month ?? ''} onChange={(e) => setMonth(e.target.value)}>
									{(() => {
										let months = [];
										for(let i = 1; i<=12; i++) {
											let tmpMonth = moment('2022-' + (i < 10 ? '0'+i : i ) + '-01').format('MMMM');
											months.push(<option key={i} value={i}>{ucfirst(tmpMonth)}</option>);
										}
										return months;
									})()}
								</select>
								<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
									{(() => {
										let years = [];
										for(let i = 2018; i<=moment().format('YYYY'); i++) {
											years.push(<option key={i} value={i}>{i}</option>);
										}
										return years.reverse();
									})()}
								</select>
							</div>
							
							<table className="box-table">
								<thead>
									<tr>
										<th></th>
										<th>FECHA</th>
										<th>NÚMERO</th>
										<th>IMPORTE</th>
										<th>DESCARGAR</th>
									</tr>
								</thead>
								<tbody>
									{monthInvoices && jsonToArray(monthInvoices).map((el, idx) => {
										let invoice = el.value;

										return (
											<tr key={idx}>
												<td><input type="checkbox" checked={selectedIds.indexOf(el.key) !== -1} onChange={(e) => toggleSelected(el.key, e.target.checked)} /></td>
												<td>{invoice.date.replaceAll('/', '-')}</td>
												<td>{invoice.number}</td>
												<td>{formatNumber(invoice.amount, 2, true, true)}€</td>
												<td>
													<button className="btn btn-link btn-sm" onClick={() => downloadInvoice(el.key)}>
														<img src={assets('assets/img/icono-descargar.svg')} alt="descargar" />
													</button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							{ (!monthInvoices || !Object.keys(monthInvoices).length) &&
								<div id="empty">
									<img src={assets('assets/img/icono-no-facturas.svg')} alt="no hay facturas" />
									No hay facturas
								</div>
							}
						</div>
						
						<div id="buttons" className={Object.keys(monthInvoices ?? []).length ? 'd-flex' : 'd-none'}>
							<button className="btn-plenoil btn-plenoil-blue2 outline" onClick={selectAll}>
								Seleccionar todo <i className="bi bi-plus"></i>
							</button>
							<button className="btn-plenoil btn-plenoil-blue2 outline" disabled={selectedIds.length <= 0} onClick={unselect}>
								Deseleccionar <i className="bi bi-dash"></i>
							</button>
							<button className="btn-plenoil btn-plenoil-blue2" disabled={selectedIds.length <= 0} onClick={downloadInvoices}>
								Descargar facturas
							</button>
						</div>
					</div>
					<div className="col-md-4 mb-4">
						<a href="https://tickets.plenoilapp.com/" onClick={(e) => {e.preventDefault(); window.alert('Lo sentimos, esta opción no está disponible actualmente');}} target="_blank" rel="noreferrer" id="ticket-to-invoices-link" className="d-flex box-plenoil box-plenoil-padded">
							<img src={assets('assets/img/icono-solicitar-otra.svg')} alt="solicitar factura" />
							SOLICITAR FACTURA
						</a>
					</div>
				</div>
			</ContainerStyled>
		</LoggedLayout>
	);
}


